<template lang="pug">
  div(:class="{'candidate-table-searching': $route.query.search}" data-test="candidate-list")
    app-table(
      v-model="activeItems"
      :headers="headers"
      :expanded.sync="expanded"
      single-expand=true
      :list="list"
      item-key="ID"
      show-select
      hide-default-footer
      multi-sort=false
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSorting"
      :server-items-length="list.length"
      :single-select="false"
      :loading="loading"
      :mobile-breakpoint='NaN'
      hide-default-header
      class="app-table elevation-1 v-data-table theme--light"
    )
        template(v-slot:top)
          .pr-4.pl-4.pt-2.pb-2.d-flex.justify-end(v-if="isActiveLabels")
            portal-target(name="candidate-labels-list")
          v-divider(v-if="isActiveLabels")

          portal(to="candidates-filters")
            actions(
              :activeItems="activeItems"
              :list="list"
              :loading="loading"
              @click:hold="updateActiveItemsHold"
            )
              template(#prepend)
                refresh-button(@click="loadList" v-show="!activeItemsLength").mr-3.pb-2
                div(v-if="activeItemsLength").app-table__selected-items-title {{ activeItemsLength}} items selected
                select-all(v-if="showSelectAll" @change="toggleSelectAllItems" :activeItemsCount="activeItemsLength" :itemsToSelectCount="pagination.count").mx-2.my-1
                v-spacer(style="max-width: 100px;")
            v-divider

        template(v-slot:header="headerRow")
          header-row(:headerRow="headerRow")

        template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
          candidate-row(
            :item="item"
            :isSelected="isSelected"
            :select="select"
            :index="index"
            :expand="expand"
            :isExpanded="isExpanded"
            :pagination="pagination"
            :primaryPagination="primaryPagination"
            :key="index"
            :labels="labels"
            :activeItems="activeItems"
            @selectedWithShiftkey="extendedSelection"
            @onGoToEdit="goToEdit"
            @click:payments="paymentsOpen"
          )

        template( v-slot:expanded-item="{ headers, item, expand }")
          td(:colspan="headers.length").pl-0.pr-0.app-table__expand
            candidate-item(
              ref="expand"
              :ID="item.ID"
              :key="item.ID"
              @close="hideExpandBy(expand)"
              @saved="updateTableDataBy(item, $event)"
            )

        template(v-slot:footer="{options}")
            .pagination(v-if="pagination")
                load-more-button(:loading="loading" :pagination="pagination" @loadMore="onLoadMoreClick")
                div.pagination__actions
                    v-pagination(
                      v-model="pagination.page"
                      @input="paginationChange"
                      :disabled="loading"
                      :length="pagination.totalPages || 0"
                      :total-visible="8"
                    )
    payments-wrapper(ref="payments")
    portal-target(name="candidate-create")
</template>

<script>
import SortModel from "./core/models/candidatesSortModel"
import { FILTERS_ARGS, CANDIDATE_PAGE, CANDIDATES_HEADERS } from './core/candidates-const'
import itemModel from "./core/candidates-reservation-model"
import paginationMixin from '@/mixins/pagination.mixin'
import PaginationModel from "@/models/paginationModel"
import CandidateBroadcast from "./core/models/candidateBroadcast"
import CandidatePageMixin from "./core/candidates-page.mixin"
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [paginationMixin, CandidatePageMixin, errorsMixin],

  data: () => ({
    headers: CANDIDATES_HEADERS,
  }),

  inject: ['svc'],

  methods: {
    async loadList({more = false}) {
      await this._loadList({more, page: CANDIDATE_PAGE.ALL})
    },
  },

  components: {
    actions: () => import('./CandidatesActions.vue'),
    appTable: () => import('@/components/global/GroupedTable.vue'),
    headerRow: () => import('./components/candidates/CandidatesHeaders.vue'),
    candidateRow: () => import('./components/candidates/CandidateRowTask.vue'),
    candidateItem: () => import('./components/item/CandidateItem.vue'),
    selectAll: () => import('./components/CandidateSelectAll.vue'),
    refreshButton: () => import('./components/CandidatesRefreshButton.vue'),
    paymentsWrapper: () => import('./components/payments/CandidatePaymentWrapper.vue'),
    loadMoreButton: () => import('@/components/widget/LoadMoreButton.vue'),
  },

  beforeDestroy() {
    this.broadcast.close()
  },

  provide: function () {
    return {
      markAsProcessed: this.markAsProcessed,
      loadList: this.loadList,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../src/app/admin/modules/candidates/assets/candidates.scss";
</style>
